import React, { Fragment, useEffect, useRef, useState } from 'react'
import ym from 'react-yandex-metrika'
import theme from '../../components/base/theme'
import Button from '../../components/button/button'
import { Image } from '../../components/Image'
import cn from '../../helpers/classnames'
import useWindow from '../../helpers/useWindow'
import s from './chief.module.css'

import Meta from './Meta'

import { ReactComponent as Archery } from '../assets/chief/archery.svg'
import { ReactComponent as Scooter } from '../assets/chief/balancing-scooter.svg'
import Burov from '../assets/chief/burov.png'
import { ReactComponent as Cyclist } from '../assets/chief/cyclist.svg'
import { ReactComponent as Face } from '../assets/chief/face.svg'
import { ReactComponent as Fire } from '../assets/chief/fire.svg'
import { ReactComponent as Gold } from '../assets/chief/gold.svg'
import { ReactComponent as Helmet } from '../assets/chief/helmet.svg'
import Kapaev from '../assets/chief/kapaev.png'
import { ReactComponent as Laptop } from '../assets/chief/laptop.svg'
import { ReactComponent as Like } from '../assets/chief/like.svg'
import { ReactComponent as Magic } from '../assets/chief/magic.svg'
import Micaliuc from '../assets/chief/micaliuk.png'
import MrChief from '../assets/chief/mrchief.png'
import { ReactComponent as Rocket } from '../assets/chief/rocket.svg'
import { ReactComponent as Shark } from '../assets/chief/shark.svg'
import Shkurat from '../assets/chief/shkurat.png'
import Yumadilov from '../assets/chief/yumadilov.png'
import Grapheme from '../assets/chief/grapheme.png'
import Romanov from '../assets/chief/romanov.jpeg'
import Gracheva from '../assets/chief/gracheva.jpg'
import Trifonov from '../assets/chief/trifonov.jpg'

const promo = [
  `Расти в&nbsp;профессии трудно. Трудно выбрать направление
  и&nbsp;придумать как двигаться вперёд. Трудно дисциплинировать
  себя, чтобы работать на&nbsp;рост. Трудно оценить прогресс.`,
  `Чтобы расти эффективно, найдите наставника, который поможет
  составить план роста и&nbsp;не&nbsp;даст его провалить. Такой
  наставник&nbsp;— &laquo;шеф&raquo;.`,
  `Шефом может быть кто угодно&nbsp;&mdash; коллега, друг или
  родственник. Если подходящего кандидата не&nbsp;нашлось, поможет
  профессиональный шеф. У&nbsp;нас как раз такой есть.`,
]

const aboutChief = [
  `С&nbsp;2009 года я работаю с&nbsp;Дизайн-бюро Артёма&nbsp;Горбунова, несколько проектов для&nbsp;примера:
  <a href='https://bureau.ru/projects/onetwotrip/'>Раз-два-трип</a>,
  <a href='https://bureau.ru/projects/glavbukh-print/'>Журнал «Главбух»</a>,
  <a href='https://bureau.ru/projects/amundsen-and-scott/'>Диаграмма экспедиций Амундсена и&nbsp;Скотта</a>.`,
  `Ещё в&nbsp;бюро я делаю разное на&nbsp;тему управления проектами:
  <a href='https://bureau.ru/bb/soviet/sinelnikov-toverovsky/'>советы</a>,
  <a href='https://bureau.ru/school/'>школу</a>,
  <a href='https://bureau.ru/projects/book-fff/'>книгу</a>.`,
  `Помог «Додо Пицце» сделать:
  <a href='https://ksoftware.livejournal.com/335478.html'>американский сайт</a>,
  <a href='https://ksoftware.livejournal.com/319469.html'>промостраницу франшизы</a>,
  <a href='https://ksoftware.livejournal.com/323040.html'>внутреннюю базу знаний</a>,
  <a href='https://ksoftware.livejournal.com/324295.html'>сайт «Одного&nbsp;миллиарда»</a>.`,
  `В&nbsp;DRIVE2.RU запустил:
  <a href='https://ksoftware.livejournal.com/354786.html'>мобильный сайт и&nbsp;приложения</a>,
  <a href='https://ksoftware.livejournal.com/401204.html'>новые «Бизнес-акаунты»</a>.`,
  `Занимаюсь собственными проектами:
  сайт&nbsp;<a href='http://fff.works'>ФФФ.воркс</a>,
  <a href='http://fff.works/octopus/'>Осьминожка навыков</a>,
  <a href='https://conspectus.io/'>Конспект</a>.`,
]

const feedback = [
  {
    avatar: Romanov,
    avatarAlt: 'Романов',
    name: '<a href="https://vk.com/@rvladislavv-chtoby-uchitsya-nuzhen-nastavnik">Владислав Романов</a>',
    text: [
      `«Я&nbsp;решил сделать свой проект. Как выбрать, что это будет? Как не&nbsp;потратить полгода на&nbsp;фигню? Как не&nbsp;слиться через две недельки? Николай помог добавить здравого смысла: думать перед тем, как броситься что-то делать и&nbsp;доделывать, сам&nbsp;бы я&nbsp;уже слился. А&nbsp;ещё показал кучу полезных блогов, статей, видео, которые меняют моё представление о&nbsp;том, что такое делать продукт».`,
    ],
  },
  {
    avatar: Burov,
    avatarAlt: 'Буров',
    name: '<a href="https://www.facebook.com/anatoly.rr/posts/10219541128343028">Анатолий Буров</a>',
    text: [
      `«И&nbsp;вот тут наступает магия. Потому что Коля задаёт простые,
      но&nbsp;крайне важные вопросы, которые почему-то мне в&nbsp;голову вовремя
      не&nbsp;пришли. Предполагаю, что это какое-то фундаментальное свойство: пока
      бродишь в&nbsp;деревьях, сложно уследить за&nbsp;всем лесом, нужен взгляд
      со&nbsp;стороны. Какой цели я пытаюсь достигнуть? А&nbsp;компания? Как буду
      измерять успех? Где чекпоинты?`,
      `Ещё Коля делится кучей разных конкретных и&nbsp;обобщённых примеров
      и&nbsp;даёт практические советы. Определить принципы руководства. Составить
      шаблоны типовых задач. Не&nbsp;эскалировать проблемы без&nbsp;крайней
      необходимости.`,
      `Конечно, большинство этих штук уже есть в&nbsp;книжках. Моя проблема
      в&nbsp;том, что погребённые под&nbsp;слоями пассивного знания нужные идеи
      вовремя не&nbsp;всплывают. Спасибо Коле, что помогает их вытащить
      на&nbsp;свет. Дополнительно вдохновляет невероятное спокойствие
      и&nbsp;осознанность Коли. Это какой-то особый скилл: сохранять
      равновесие в&nbsp;любых ситуациях. Вдохновляет даже просто наблюдение
      за&nbsp;этим со&nbsp;стороны».`,
    ],
  },
  {
    avatar: Micaliuc,
    avatarAlt: 'Микалюк',
    name: '<a href="https://www.facebook.com/anatolie.micaliuc/posts/10213262182777091">Анатолий Микалюк</a>',
    text: [
      `«Моя цель не&nbsp;на&nbsp;один месяц, но&nbsp;промежуточные результаты
      уже есть. По&nbsp;одному из&nbsp;проектов расширились полномочия, стали
      доверять более сложные задачи. По&nbsp;другому&nbsp;— увеличилась
      базовая ставка. Вдобавок нашёл себе в&nbsp;команду дизайнера
      (правда, ещё не&nbsp;успели поработать вместе). Устаканил график
      публикаций в&nbsp;инстаграме и&nbsp;увеличил число подписчиков».`,
    ],
  },
  {
    avatar: Yumadilov,
    avatarAlt: 'Юмадилов',
    name: '<a href="https://www.facebook.com/v.yumadilov/posts/3149455625094905">Вадим Юмадилов</a>',
    text: [
      `«Ты научил меня подходам „Делать не&nbsp;значит сделать“, „Можно всё“
      и&nbsp;„ФФФ“. Сначала в&nbsp;статьях на&nbsp;ЖЖ, потом в&nbsp;совместных
      проектах. Теперь это моя прошивка и&nbsp;я автоматически применяю метод,
      если он нужен».`,
    ],
  },
  {
    avatar: Shkurat,
    avatarAlt: 'Шкурат',
    name: '<a href="https://www.notion.so/mikhail/4411a290e20e47bdbaf800a68c5abb4e">Михаил Шкурат</a>',
    text: [
      `«Главное для меня&nbsp;то, что каждая встреча это прорыв, в&nbsp;том
      смысле, что Николай круто помогает мне, с&nbsp;чётким и&nbsp;ясным
      видением, что смогу сделать за&nbsp;неделю, и&nbsp;ещё более лёгким
      пониманием как это сделать».`,
    ],
  },
  {
    avatar: Kapaev,
    avatarAlt: 'Капаев',
    name: `<a href="https://www.facebook.com/uporlezha/posts/3951372998263606">Дмитрий Капаев</a>
      <span style="font-weight: normal;">, <a href="https://glubina.studio">Глубина</a></span>`,
    text: [
      `«Коля отличный ментор&nbsp;— он&nbsp;помог мне отпустить вожжи
      на&nbsp;проектах и&nbsp;заняться стратегически важными делами, построить
      процесс управления проектами, и, буквально, выпнул меня на&nbsp;путь
      создания новой стратегии компании».`,
    ],
  },
  {
    name: 'Илья Фёдоров',
    text: [
      `«Переиначивая классика&nbsp;— все успешные проекты счастливы одинаково,
      все провальные проекты несчастливы по-своему. Так ты&nbsp;думаешь, когда
      сталкиваешься с&nbsp;серьёзным барьером в работе. Ты просто уверен,
      что твоя проблема уникальна. Никто раньше с&nbsp;подобным
      не&nbsp;сталкивался. В&nbsp;такой формулировке так и&nbsp;есть.
      Но, скорее всего, было множество очень похожих проблем, с&nbsp;которыми
      люди сталкивались ранее. В&nbsp;этом и&nbsp;есть неоценимая помощь
      опции „посоветоваться с&nbsp;шефом“. Ты получаешь доступ к&nbsp;огромному
      опыту Николая, к&nbsp;архиву реализованных задач и&nbsp;побеждённых
      проблем. Через них ты формируешь разные взгляды на&nbsp;свою проблему,
      примеряешь решения из&nbsp;похожих задач. На&nbsp;выходе получаешь
      несколько вариантов, как решить свою. Это невероятно экономит силы
      и&nbsp;время. За&nbsp;пару встреч перемещаешься из&nbsp;точки
      „это катастрофа“ в&nbsp;точку „у&nbsp;меня есть понятный план,
      в&nbsp;который я верю“. Настоящий телепорт&nbsp;— рекомендую!»`,
    ],
  },
  {
    avatar: Grapheme,
    avatarAlt: 'Графема',
    name: '<a href="https://www.notion.so/afbec81c79174952b8a959ffef7fc5c2">Агентство «Графема»</a>',
    text: [
      `Для нас шефство&nbsp;— это ежемесячные встречи и&nbsp;домашние задания.
      Встречаемся в&nbsp;Зуме, общаемся и&nbsp;получаем домашку в&nbsp;Телеграме.
      Вопросы мы готовим заранее, а&nbsp;на встрече обсуждаем их с&nbsp;Колей.
      Вопросы могут быть самыми разными: про&nbsp;управление временем,
      отношения с&nbsp;клиентами, мотивацию команды, договоры и, конечно же,
      про&nbsp;управление проектами.`,
      `Этот опыт стал ценным для нас, потому что: имеем возможность посмотреть
      на&nbsp;свои проблемы со&nbsp;стороны, получаем советы от&nbsp;опытного
      наставника, смотрим на&nbsp;динамику через месяц, два, три.`,
      `Рекомендуем обратиться к&nbsp;Коле, если вы хотите развивать ваших
      менеджеров проектов, процессы внутри компании, отношения с&nbsp;клиентами.
      Мы, например, пока не&nbsp;выросли в супер-менеджеров, процессы идеально
      не&nbsp;наладили, с&nbsp;клиентами бывают проблемы. Но, определённо, стали лучше.`,
    ],
  },
  {
    name: '<a href="https://t.me/romanRokanov">Роман Роканов</a>',
    text: [
      `В&nbsp;чём польза от&nbsp;этой услуги? Зайду с&nbsp;козырей&nbsp;&mdash;
      Николай может помочь вам перейти на&nbsp;новый уровень. Звучит просто,
      и&nbsp;это на&nbsp;самом деле не&nbsp;так уж&nbsp;и&nbsp;сложно. У&nbsp;вас
      уже есть все кусочки этого паззла.`,
      `Встречи с&nbsp;Николаем помогли мне определиться с&nbsp;направлением
      развития, избавиться от&nbsp;ощущения, что все забуксовало и&nbsp;непонятно,
      что с&nbsp;этим делать.`,
      `Нескольких встреч хватило, чтобы я&nbsp;осознал свои слабые стороны,
      прокачал их&nbsp;и&nbsp;стал использовать не&nbsp;стесняясь.`,
      `На&nbsp;первых встречах, какое-то странное впечатление от&nbsp;того,
      что те&nbsp;вещи, которые казались какими-то супер сложными
      и&nbsp;не&nbsp;решаемыми, под другим углом выглядят вполне себе решаемыми
      или вообще не&nbsp;стоящими внимания. Вот этот другой взгляд
      вы&nbsp;и&nbsp;получаете, а&nbsp;также помощь в&nbsp;подготовке
      к&nbsp;переговорам, настройке процессов.`,
    ],
  },
  {
    avatar: Gracheva,
    avatarAlt: 'Грачёва',
    name: '<a href="https://t.me/sthdoing/98">Таня Грачёва</a>',
    text: [
      `Расскажу, как мы&nbsp;работаем с&nbsp;моим шефом, Колей&nbsp;Товеровским. Узнала про него благодаря курсам в&nbsp;Бюро&nbsp;Горбунова, и&nbsp;захотелось у&nbsp;него учиться.`,
      `Конечно, расти можно и&nbsp;в&nbsp;одиночку. Главное, не&nbsp;перегнуть палку на&nbsp;каком-то этапе и&nbsp;не&nbsp;закончить потом, как Мартин&nbsp;Иден. Но&nbsp;приятнее проходить этот путь с&nbsp;мастером, который подскажет, как сделать все это эффективнее и&nbsp;безопасно для здоровья.`,
      `В&nbsp;бюро учат относиться к&nbsp;клиенту с&nbsp;заботой и&nbsp;предлагать максимум пользы. С&nbsp;Колей я&nbsp;ощутила, как при этом может себя чувствовать сам клиент: к&nbsp;такому сервису действительно хочется возвращаться.`,
      `Работаем в&nbsp;формате менторства. Коля помогает настроить основу&nbsp;&mdash; продуктивное мышление и&nbsp;отношение&nbsp;&mdash; и&nbsp;найти новые способы для решения моих задач. Учусь планированию, делегированию, настройке процессов, решаем проблему с&nbsp;перегрузом.`,
      `Не&nbsp;только рабочие задачи разбираем, кстати. Бывают ещё и&nbsp;бытовые, которые тоже топят будь здоров.`,
      `Ко&nbsp;встрече готовлю вопросы и&nbsp;проблемы, к&nbsp;которым непонятно, как подойти. Разбираем их&nbsp;с&nbsp;Колей. Со&nbsp;встречи ухожу с&nbsp;ощущением порядка и&nbsp;материалами по&nbsp;теме&nbsp;&mdash; примерами ведения проектов, видео, списком для чтения.`,
      `Ну&nbsp;и&nbsp;с&nbsp;Колей просто приятно общаться. Он&nbsp;даже критикует как-то весело, очень заряжает позитивом. Приходишь иной раз какой-то нервный, уходишь&nbsp;&mdash; доброй уверенной походкой&nbsp;:-)`,
    ],
  },
  {
    avatar: Trifonov,
    avatarAlt: 'Трифонов',
    name: 'Владимир Трифонов',
    text: [
      `С Колей я шефствуюсь уже второй сезон. Ценю, что на встречах он не опускается сам и не даёт опускаться мне до рутины. Постоянно говорит, что надо заниматься не фигней, а чем-то более важным тогда, когда хочется забить на все и пилить простые и понятные вещи. Подсказывает куда идти, но почти никогда не даёт прямых советов. Приходится думать головой 😱 Сложно, но иногда получается.`,
    ],
  },
]

const getInitials = (text) =>
  text
    .replace(/<[^>]*>?/gm, '')
    .replace(/[«»]/g, '')
    .split(' ')
    .map((w) => w[0])
    .slice(0, 2)
    .filter((w) => w)
    .join('')

const Section = (props) => (
  <div
    className={cn(s.section, props.className)}
    style={{ '--background': props.background }}
  >
    {props.children}
  </div>
)

const Ps = ({ text }) =>
  text.map((p, i) => <p dangerouslySetInnerHTML={{ __html: p }} key={i} />)

const Balloon = ({ isRight, children }) => {
  const [scrollTop, viewportHeight] = useWindow()
  const [isVisible, setIsVisible] = useState(false)
  const ref = useRef(null)

  useEffect(() => {
    const top =
      ref.current.getBoundingClientRect().top +
      document.documentElement.scrollTop
    setIsVisible(scrollTop + viewportHeight * 0.9 > top)
  }, [scrollTop, viewportHeight])

  return (
    <div
      className={cn(
        s.balloon,
        !isVisible && s.isBalloonHidden,
        isRight && s.isBalloonRight
      )}
      ref={ref}
    >
      {children}
    </div>
  )
}

const Balloons = ({ left, right }) => (
  <div className={s.balloonHolder}>
    <Balloon isRight>{right} </Balloon>
    <Balloon>{left}</Balloon>
  </div>
)

const HelloAnimation = () => {
  const [scrollTop, viewportHeight] = useWindow()
  const [isVisible, setIsVisible] = useState(false)
  const ref = useRef()

  useEffect(() => {
    const top =
      ref.current.getBoundingClientRect().top +
      document.documentElement.scrollTop
    setIsVisible(scrollTop + viewportHeight * 0.8 > top)
  }, [scrollTop, viewportHeight])

  return (
    <div ref={ref}>
      <Scooter className={cn(s.scooter, isVisible && s.isScooterVisible)} />
    </div>
  )
}

const ClientFace = () => {
  return (
    <div className={s.faceWrapper}>
      <Face className={s.face} />
      <div className={s.faceAnimationHolder}>
        <Fragment>
          <Magic className={cn(s.faceSign, s.faceMagic)} />
          <Like className={cn(s.faceSign, s.faceLike)} />
          <Laptop className={cn(s.faceSign, s.faceLaptop)} />
        </Fragment>
      </div>
    </div>
  )
}

const RocketAnimation = () => {
  const [scrollTop, viewportHeight] = useWindow()
  const [isVisible, setIsVisible] = useState(false)
  const ref = useRef()

  useEffect(() => {
    const top =
      ref.current.getBoundingClientRect().top +
      document.documentElement.scrollTop
    setIsVisible(scrollTop + viewportHeight > top)
  }, [scrollTop, viewportHeight])

  return (
    <div
      ref={ref}
      className={cn(s.rocketHolder, isVisible && s.isRocketVisible)}
    >
      <Fire className={s.rocketFire} />
      <Rocket className={s.rocket} />
    </div>
  )
}

const START_ID = 'stickyButtonStart'
const END_ID = 'stickyButtonEnd'
const DOM_LOADING_DELAY = 500
const INITIAL_STYLE = 2000

const StickyButton = (props) => {
  const [isLoaded, setIsLoaded] = useState(false)
  const [styles, setStyles] = useState({
    height: INITIAL_STYLE,
    top: INITIAL_STYLE,
  })

  useEffect(() => {
    const handleHeight = () =>
      setTimeout(() => {
        const start =
          document.getElementById(START_ID).getBoundingClientRect().top +
          document.documentElement.scrollTop
        const end =
          document.getElementById(END_ID).getBoundingClientRect().top +
          document.documentElement.scrollTop

        setStyles({ height: end - start, top: start })
      }, DOM_LOADING_DELAY)
    handleHeight()
    window.addEventListener('resize', handleHeight)
    return () => {
      window.removeEventListener('resize', handleHeight)
    }
  }, [])

  useEffect(() => setIsLoaded(true), [])

  return (
    <div
      className={cn(s.stickyHolder, isLoaded && s.isStickyLoaded)}
      style={styles}
    >
      <Button href={props.href}>{props.children}</Button>
    </div>
  )
}

const ChiefPage = () => {
  const [isLoaded, setIsLoaded] = useState(false)
  useEffect(() => setIsLoaded(true), [])

  return (
    <Fragment>
      <Meta />
      <div className={s.webpage} style={{ background: '#e44a66' }}>
        <div className={s.phone}>
          <StickyButton
            href='https://t.me/nicktoverovskiy'
            onClick={() => ym('reachGoal', 'ASK_CHIEF_INTEXT')}
          >
            Записаться к&nbsp;шефу
          </StickyButton>
          <Section className={s.first} background={theme.purpur}>
            <nav className={s.headerMenu}>
              <div className={s.menu}>
                <div className={s.menuItem}>
                  <a className={s.menuItemLink} href='/'>
                    ФФФ.воркс
                  </a>
                </div>
                <div className={s.menuItem}>
                  <a className={s.menuItemLink} href='../blog/'>
                    Блог
                  </a>
                </div>
              </div>
              <div className={`${s.menuItem} ${s.menuItemLast}`}>
                <a
                  className={s.menuItemLink}
                  href='https://t.me/fffworks'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  Канал в телеграме
                </a>
              </div>
            </nav>
            <div className={cn(s.helloText, isLoaded && s.isHelloLoaded)}>
              <div className={s.birka}>Услуга</div>
              <h1 className={s.heading}>Посове&shy;товаться с&nbsp;шефом</h1>
            </div>
            <div
              className={cn(
                s.helloText,
                s.helloTextDelayed,
                isLoaded && s.isHelloLoaded
              )}
            >
              <Ps text={promo} />
              <div className={s.cta}>
                <div id='stickyButtonStart' />
              </div>
            </div>
            <HelloAnimation />
          </Section>
          <Section className={s.light} background={theme.yellow}>
            <Balloons
              right='Кому пригодится помощь шефа?'
              left='Любому кто, затрудняется сам организовать личный рост'
            />
            <h3>
              Примеры задач,
              <br />
              с&nbsp;которыми поможет шеф
            </h3>
            <ul>
              <li>
                Улучшить навыки{' '}
                <Helmet
                  style={{ position: 'relative', top: -2 }}
                  className={s.icon}
                />
                управления{' '}
              </li>
              <li>Возглавить отдел</li>
              <li>Систематизировать текущий проект</li>
              <li>Собрать сильную команду</li>
              <li>Подготовиться к&nbsp;стартапу</li>
              <li>
                Наладить отношения {<Shark className={s.icon} />}
                с&nbsp;сотрудниками
              </li>
              <li>Освоить новую профессию</li>
              <li>Повысить продуктивность</li>
              <li>
                Начать больше{' '}
                {
                  <Gold
                    className={s.icon}
                    style={{ position: 'relative', top: -3 }}
                  />
                }
                зарабатывать
              </li>
              <li>Научиться запускать проекты в&nbsp;срок</li>
              <li>Стать ведущим специалистом в&nbsp;компании</li>
            </ul>
            <ClientFace />
          </Section>
          <Section background={theme.ultramarine}>
            <Balloons
              right='Kак это работает?'
              left='Вы созваниваетесь с&nbsp;шефом в&nbsp;скайпе, и&nbsp;он помогает наладить рост'
            />
            <Archery className={s.archery} />
            <h3>На&nbsp;первой встрече</h3>
            <ol>
              <li>Определяетесь с&nbsp;целью</li>
              <li>Обсуждаете направление роста</li>
              <li>Придумываете, как в&nbsp;этом направлении двигаться</li>
              <li>Составляете план: задачи, книги, встречи</li>
            </ol>
            <Cyclist className={s.cyclist} />
            <h3>На&nbsp;следующих встречах</h3>
            <ol>
              <li>Обсуждаете прогресс и&nbsp;возникшие сложности</li>
              <li>Добавляете интересные задачи</li>
              <li>Если возникли проблемы&nbsp;— разбираетесь, как решить</li>
            </ol>
          </Section>
          <Section background={theme.purpur}>
            <Balloons
              right='Сколько всего встреч нужно?'
              left='Зависит от вашей задачи'
            />
            <h3>Одна встреча для пинка</h3>
            <p>
              Этого хватит для начала роста. Шеф поможет определиться
              с&nbsp;целью и&nbsp;планом, а&nbsp;двигаться дальше будете уже
              самостоятельно.
            </p>
            <h3>Раз в&nbsp;неделю для турбоускорения</h3>
            <p>
              Быстрый темп для тех, кто хочет получить результат
              в&nbsp;кратчайшие сроки.
            </p>
            <h3>Раз в&nbsp;две-четыре недели для планомерного роста</h3>
            <p>
              Оптимальный темп для достижения стратегических целей.
              В&nbsp;промежутке между встречами будет достаточно времени, для
              самостоятельной работы, а&nbsp;шеф поможет скорректировать
              движение и&nbsp;не&nbsp;забить.
            </p>
            <RocketAnimation />
          </Section>
          <Section className={s.light} background={theme.yellow}>
            <Balloons
              right='Кто будет шефом?'
              left='Николай Товеровский. Рад знакомству'
            />
            <Image className={s.mrchief} src={MrChief} alt='Товеровский' />
            <Ps text={aboutChief} />
          </Section>
          <Section background={theme.ultramarine}>
            <Balloons
              right='А&nbsp;отзывы есть?'
              left='Да.&nbsp;Если напишете свой, мы&nbsp;его тоже опубликуем'
            />
            {feedback.map((item, i) => (
              <Fragment key={i}>
                <h3>
                  {item.avatar && (
                    <Image
                      className={s.avatar}
                      src={item.avatar}
                      alt={item.avatarAlt}
                    />
                  )}
                  {!item.avatar && (
                    <div className={s.avatar}>{getInitials(item.name)}</div>
                  )}
                  <div dangerouslySetInnerHTML={{ __html: item.name }} />
                </h3>
                <Ps text={item.text} />
              </Fragment>
            ))}
          </Section>
          <Section id='cta' background={theme.purpur}>
            <Balloons
              right='Cколько стоит консультация шефа?'
              left='Встреча с&nbsp;шефом стоит 6000&nbsp;₽ и&nbsp;длится час'
            />
            <div className={s.contacts}>
              <p>
                Почта:{' '}
                <a href='mailto:nick.toverovskiy@gmail.com?subject=%D0%A5%D0%BE%D1%87%D1%83%20%D0%BF%D0%BE%D1%81%D0%BE%D0%B2%D0%B5%D1%82%D0%BE%D0%B2%D0%B0%D1%82%D1%8C%D1%81%D1%8F%20%D1%81%20%D1%88%D0%B5%D1%84%D0%BE%D0%BC&amp;body=%D0%94%D0%BE%D0%B1%D1%80%D1%8B%D0%B9%20%D0%B4%D0%B5%D0%BD%D1%8C!%20%D0%AF%20%D1%85%D0%BE%D1%87%D1%83%20%D0%BF%D0%BE%D1%81%D0%BE%D0%B2%D0%B5%D1%82%D0%BE%D0%B2%D0%B0%D1%82%D1%8C%D1%81%D1%8F%20%D1%81%20%D1%88%D0%B5%D1%84%D0%BE%D0%BC.%0A%0A%D0%97%D0%B0%D0%B4%D0%B0%D1%87%D0%B0%20%D0%B8%D0%BB%D0%B8%20%D1%86%D0%B5%D0%BB%D1%8C%3A%0A%5B%D0%9D%D0%B0%D0%BF%D0%B8%D1%88%D0%B8%D1%82%D0%B5%2C%20%D1%87%D0%B5%D0%B3%D0%BE%20%D1%85%D0%BE%D1%82%D0%B8%D1%82%D0%B5%20%D0%B4%D0%BE%D0%B1%D0%B8%D1%82%D1%8C%D1%81%D1%8F%20%D0%B8%D0%BB%D0%B8%20%D0%B4%D0%BE%D1%81%D1%82%D0%B8%D1%87%D1%8C.%20%D0%95%D1%81%D0%BB%D0%B8%20%D0%BD%D0%B5%20%D0%B7%D0%BD%D0%B0%D0%B5%D1%82%D0%B5%20%E2%80%94%20%D0%BD%D0%B5%D1%82%20%D0%BF%D1%80%D0%BE%D0%B1%D0%BB%D0%B5%D0%BC%2C%20%D0%BE%D1%81%D1%82%D0%B0%D0%B2%D1%8C%D1%82%D0%B5%20%D1%8D%D1%82%D0%BE%20%D0%BF%D0%BE%D0%BB%D0%B5%20%D0%BF%D1%83%D1%81%D1%82%D1%8B%D0%BC%2C%20%D1%88%D0%B5%D1%84%20%D0%BF%D0%BE%D0%BC%D0%BE%D0%B6%D0%B5%D1%82%20%D1%81%D1%84%D0%BE%D1%80%D0%BC%D1%83%D0%BB%D0%B8%D1%80%D0%BE%D0%B2%D0%B0%D1%82%D1%8C%20%D0%BD%D0%B0%20%D0%B2%D1%81%D1%82%D1%80%D0%B5%D1%87%D0%B5%5D'>
                  nick.toverovskiy@gmail.com
                </a>
              </p>
              <p>
                Телеграм:{' '}
                <a
                  target='_blank'
                  rel='noopener noreferrer'
                  href='https://tlgg.ru/@nicktoverovskiy'
                >
                  @nicktoverovskiy
                </a>
              </p>
            </div>
            <div className={s.cta}>
              <div style={{ padding: '20px 0' }}>
                <div id='stickyButtonEnd'></div>
              </div>
            </div>
            <div className={s.cta}>
              <p>
                Или подписаться на&nbsp;телеграм-канал с&nbsp;советами
                по&nbsp;личному росту
              </p>
              <Button
                small
                transparent
                target='_blank'
                rel='noopener noreferrer'
                href='https://t.me/fffchief'
              >
                Посове­товаться с&nbsp;шефом
              </Button>
            </div>
            <div className={s.spacer} />
          </Section>
        </div>
      </div>
    </Fragment>
  )
}

export default ChiefPage
